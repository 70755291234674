import React from 'react';

import './Nav.css';

function Nav({setPage_}) {
  return (
    <nav className="navbar">
      <ul className="navbar-list">
        <li><a className="navbar-link" onClick={() => setPage_("")}>HOME</a></li>
        <li><a onClick={() => setPage_("press")}>PRESS</a></li>
        <li><a onClick={() => setPage_("listen")}>LISTEN</a></li>
      </ul>
    </nav>
  );
}
export default Nav;