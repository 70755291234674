import React from 'react';

import './Music.css';
import '../Pages/All.css';

function Music({title_, filename_}) {
  return (
    <ul className='musicObj' style={{margin: '1em'}}>
      <span style={{marginBottom: '.5em'}}>{title_}</span>
      <audio controls>
      <source src={`assets/music/${filename_}.mp3`} type="audio/mpeg"/>
      </audio> 
    </ul>
  );
}
export default Music;