import React, { useState, useEffect } from 'react';
import Featured from '../Components/Featured';

import './All.css'; 
import './Home.css'; 

function Home() {
  return (
    <main>
      <h1>AN BINH TAT</h1>
      
      <img className='banner' src='assets/bannerIMG.jpg' style={{
        width: 'max(100vw, 1000px)'
      }}/>

      <section>
        <h2>
          FEATURED WORKS
        </h2>

        <div className='featuredColl'>
          <Featured link_='unburiedIMG' title_={'The Unburied Sounds of a Troubled Horizon (2022)'}/>
          <Featured link_='oursIMG' title_={'What Is Research? (2021)'}/>
          <Featured link_='mrgloopIMG' title_={'Mr. Gloop (2021)'}/>
        </div>
      </section>        
      
      <section>
        <h2 style={{
          marginTop: '3em'
        }}>
          ARTIST STATEMENT
        </h2>

        <div className='statement'>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <img className='statementIMG' src='assets/portraitIMG.jpg'/>
            <label>
              Photo credit: Tuan Andrew Nguyen
            </label>
          </div>
          
          <p>
            “What am I?”<br/><br/>

            The essence of my life has been the question of who I am as a person. As a child of Vietnamese and Chinese parents raised on British and American media and curricula, my youth was plagued with that simple question. Growing up at the cusp of the computer revolution in Vietnam, I also became enamoured with the potential of the modern computer while having the luxury of remembering what life was like without it. As a consequence of these two facets of my childhood, I became a composer engaged in a battle between the antique and the contemporary, as well as the foreign and domestic. <br/><br/>

            My music integrates electronic and acoustic music with a blend of Western European traditional instruments and Vietnamese traditional instruments to create works that bridge both genres for an international audience. Believing in the harmony between the natural and the artificial, my music threads the needle, usually through augmenting live instrumentation with procedurally generated sounds, processed audio, and plenty of cyber-aleatorism.<br/><br/>

            In addition to having an interest in the Western contemporary music scene, I also feature many Vietnamese musical instruments as well as Vietnamese musical forms and styles in my compositions with the hope of promoting the sounds outside of my home country. As society continues to grow curious of sounds from non-Western Classical cultures, I believe the beauty of my country’s music cannot be neglected, and should stand as an equal to Indonesian, Indian, Japanese, and West-African music in the World Music and Ethnomusicology scene.<br/><br/>

            While I can’t answer that simple first question, and I may never be able to, the exploration of that question is the foundational principle to my music, and the pursuit of the answer has always been more meaningful to me than the answer itself. I am what I am.
          </p>
        </div>
      </section>
      
    
    </main>   
  );
}

export default Home;
