import React from 'react';

import './Footer.css';

function Footer() {
  return (
    <footer>
      <div className="social-list" style={{
        display: 'flex',
      }}>
        <a href="https://www.instagram.com/binhantat" target='_blank'><img src='assets/social/igICO.png'/></a>
        <a href="https://www.youtube.com/@btat16" target='_blank'><img src='assets/social/ytICO.png'/></a>
        <a href="https://soundcloud.com/binh-an-tat" target='_blank'><img src='assets/social/scICO.png'/></a>
        <span style={{fontSize: '1.5em', marginLeft: '.45em'}}>幹</span>
      </div>
    </footer>
  );
}
export default Footer;