import React, { useState, useEffect } from 'react';
import Nav from './Components/Nav';
import Footer from './Components/Footer';

import Home from './Pages/Home';
import Press from './Pages/Press';
import Listen from './Pages/Listen';



import './App.css'; 

function App() {
  let [page, setPage] = useState("");

  useEffect(() => {
    console.log(page);
    window.history.pushState("object or string", "Title", "/" + page);

  }, [page]);
  
  return (
    <>
      <Nav setPage_={setPage} />
        {page === "" ? <Home/> : <></>}
        {page === "press" ? <Press/> : <></>}
        {page === "listen" ? <Listen/> : <></>}
      <Footer/>
    </>
  );
}

export default App;
