import React, { useState, useEffect } from 'react';
import Music from '../Components/Music';

import './All.css'; 
import './Listen.css'; 

function Listen() {
  return (
    <main>
      <h1>AN BINH TAT</h1>
      
      <img className='banner' src='assets/banner3IMG.jpg' style={{
        width: 'max(100vw, 1000px)'
      }}/>      
      
      <section>
        <h2>
          INDEPENDENT PROJECT SAMPLES
        </h2>

        <div className='music'>          
          <h3>2023:</h3><br/><br/>
          <Music title_='Romance for Orchestra' filename_='Romance for Orchestra'/>
          <Music title_='By the Pond' filename_='By the Pond'/>

          <h3>2022:</h3><br/>
          <Music title_='Dream of a Motorised Forest' filename_='Dream of a Motorised Forest'/>
          <Music title_='Harmony of the Spheres: 0. Sol' filename_='0 - Sol'/>
          <br/>

          <h3>2021:</h3><br/>
          <Music title_='Circle Dance of the Infernal Imps' filename_='Circle Dance of the Infernal Imps'/>
          <Music title_='Theme and Variations in F for Strings' filename_='Theme and Variations for Strings'/>
          <br/>

          <h3>2020:</h3><br/>
          <Music title_='Creatures of the Night (excerpt)' filename_='Creatures of the Night (excerpt)'/>
          <Music title_='Organ Concerto in D, "Baroque": I. Allegro Moderato' filename_='Organ Concerto in D major'/>
          <br/>
          <br/>

          <h3>2019:</h3><br/>
          <Music title_='String Quartet in G minor' filename_='String Quartet in G minor'/>
          <Music title_='Symphony in D, "The Royal Hunt": IV. Finale' filename_='Symphony No.1 in D, The Royal Hunt'/>
          <Music title_='Wind Quartet No. 1, "Tea and Coffee"' filename_='Wind Quartet No. 1, Tea and Coffee'/>


        </div>
      </section>
      
    
    </main>   
  );
}

export default Listen;
