import React, { useState, useEffect } from 'react';

import './All.css'; 
import './Press.css'; 

function Press() {
  return (
    <main>
      <h1>AN BINH TAT</h1>
      
      <img className='banner' src='assets/banner2IMG.jpg' style={{
        width: 'max(100vw, 1000px)'
      }}/>      
      
      <section>
        <h2>
          PERSONAL BIOGRAPHIES / PRESS KIT
        </h2>

        <div className='presskit'>          
          <h3>Short Biography (50 words):</h3><br/><br/>
          <p>Born December 7th, 1997, An Binh Tat is a Vietnamese/Chinese composer with a BFA from Cal State Long Beach studying in CalArts for his MFA in composition. With an interest in interactive media, he is pursuing a career in video game scoring while publishing original electronic and acoustic music.</p><br/><br/><br/>

          <h3>Medium Biography (100 words):</h3><br/><br/>
          <p>Born on December 7th, 1997, An Binh Tat is a Vietnamese/Chinese composer residing in the South Bay. A jazz and classical enthusiast, he is studying in CalArts for his Master’s in Experimental Sound Practice and has a bachelor's in composition from CSULB. Being affected by traditional Vietnamese music and the soundtracks of various indie games, he developed a deep interest in interactive media and is currently pursuing a career in video game scoring and installation work. In addition to symphonic game scores, he writes more experimental tracks in the genres of electronic music, jazz, neoclassical, and Vietnamese traditional fusion.</p><br/><br/><br/>

          <h3>Detailed Biography (300 words):</h3><br/><br/>
          <p>Born on December 7th, 1997, An Binh Tat is a Vietnamese/Chinese composer residing in the South Bay. As a child growing up in the newly industrialising Vietnam, he developed a passion for playing classical music from performing in youth orchestras. Being from a financially challenged family, he spent a majority of his childhood without digital children’s entertainment. However, when reaching his teenage years, he became interested in the world of indie gaming as a medium of artistic expression. It was after understanding the impact of the interactive medium of gaming on users that he became devoted to a future scoring for it.<br/><br/> 
          At California State Long Beach (CSULB), he received a Bachelor of Fine Arts in the field of Composition, collaborating with various film and media directors in the process. During this period, his interest in the use of digital audio manipulation became solidified as he performed in, and wrote for, the CSULB Laptop Ensemble. Since graduating, he is further pursuing his studies at the California Institute of the Arts (CalArts) as a Master of Fine Arts student in Experimental Sound Practices. He is deepening his understanding of integrating acoustic and electronic soundworlds as well as his understanding of Vietnamese music cultures.<br/><br/> 
          Until he achieves a comfortable life in video game scoring, he spends most of his time working on independent projects and collaborations with visual artists. With influences from the world of hip-hop, classical, jazz, and Vietnamese traditional music, his musical style is an amalgamation of multiple different musical styles from different eras and cultures. His biggest influence for orchestral writing is the works of Stravinsky, where he demonstrates a breadth of orchestral colours. However, he has recently returned to his home for inspiration and has found a voice in writing music for traditional instruments in modern contexts.</p>
        </div>
      </section>
      
    
    </main>   
  );
}

export default Press;
