import React from 'react';

import './Featured.css';

function Featured({link_, title_}) {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'end',
      width: 'max(40%, 400px)',
      margin: '25px'
    }}>
      <img className='featuredIMG' src={`assets/${link_}.jpg`} style={{
        borderRadius: '30px',
        width: '100%',
      }}/>
      <span style={{
        position: 'absolute',
        zIndex: '2',
        fontSize: 'min(1.25rem, 4.5vw)',
        color: 'white',
        transform: 'translate(0.5em, -1.25em)',
        width: 'min-content', 
        textShadow: 'black 3px 3px 3px',
        pointerEvents: 'none'
      }}>
        {title_}
      </span>
    </div>
  );
}
export default Featured;